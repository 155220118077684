import React from "react";
import ReelAppsLogo from '../../images/ReelApps.png';

const LoginFooter = () => {
    return (
        <>
            <footer className="appFooter">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="footer-single">
                                <ul className="footer-list-left">
                                    <li><a href="http://reelapps.io/terms.html" target="_blank" rel="noreferrer">TERMS </a> | </li>
                                    <li><a href="http://reelapps.io/privacy.html" target="_blank" rel="noreferrer">PRIVACY</a>  | </li>
                                    <li><a href="http://support.vineasx.com/" target="_blank" rel="noreferrer">SUPPORT</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="footer-single">
                                <p>You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
                                <p>© {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="_blank"  rel="noreferrer">VineaSX Solutions LLC.</a> Developed and Maintained by <a href="http://www.vega6.com/" target="_blank"  rel="noreferrer">Vega6</a></p>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-single reelLogo">
                                <a href="https://reelapps.io/" target="_blank" rel="noreferrer"><img src={ReelAppsLogo} alt=""/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default LoginFooter;