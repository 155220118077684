import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SweetAlertTwo from "../SweetAlertTwo";
import { addRebrandData, onDeleteRebrand } from "../../Actions/RebrandAction";
import Swal from "sweetalert2";

const Rebranding = () => {

    const dispatch = useDispatch();
    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);
    const [loader, setLoader] = useState(false);
    const [fileData, setFileData] = useState('');
    const [name, setName] = useState('');
    const [show, setShow] = useState(false)

    const onConfirm = (Swal) => {
        let data = { id: rebrand.data.id }
        dispatch(onDeleteRebrand(data, setLoader, Swal))
        setShow(false)
    }

    const onInputFile = (e) => {
        setFileData(e.target.files[0]);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG') {
            if (fileData.size < 5000000) {
                setLoader(true);
                let formData = new FormData();
                formData.append('name', name);
                formData.append('logo', fileData);
                dispatch(addRebrandData(formData, setLoader, setFileData, setName));

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max uploaded size for image is 5MB!',
                    confirmButtonColor: "#d03e3e"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#d03e3e"
            })
        }
    }

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return (
        <>
            <div className="upgrade-content">
                <h3>Rebranding</h3>
                <form className="row  mt-5 bg-table justify-content-center align-items-center text-center" method="post" onSubmit={(e) => onFormSubmit(e)}>
                    <div className="col-11 p-0" style={{ height: "200px", backgroundColor: "rgb(255, 255, 255)", border: "1px dashed #000" }}>
                        <div style={{ height: "100%", width: "100%", cursor: "pointer" }} >
                            <div className="upload-btn-wrapper">
                                <div className="d-flex mt-4 justify-content-center align-items-center flex-column">
                                    <button
                                        style={{
                                            backgroundColor: "rgb(255, 255, 255)",
                                            color: "black",
                                            border: "none",
                                            fontSize: "1rem"
                                        }}
                                    >
                                        {fileData?.name ? fileData.name : <> Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed) <br /> (210x34) Pixels recommended</>}

                                    </button>
                                    <i
                                        className="fas fa-cloud-upload-alt"
                                        aria-hidden="true"
                                        style={{
                                            display: "block",
                                            color: "black",
                                            fontSize: "2rem",
                                            paddingTop: 10
                                        }}
                                    />
                                </div>
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    name="file"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        padding: 0,
                                        cursor: "pointer"
                                    }}
                                    onChange={(e) => onInputFile(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12 mt-4 p-1">
                        <div className="form-group form-group-mb">
                            <label className="input-label text-white ">Brand Name </label>
                            <input
                                className="form-control input-dark-accnt brandonfocus personal-info  w-100"
                                type="text"
                                name="firstName"
                                placeholder="Enter your brand Name"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col col-md-12 text-center mt-3">
                        <button
                            type="submit"
                            className="demoLink-2 mt-0"
                            disabled={loader}
                        >
                            {loader ? <> Saving <i className="fa fa-spinner fa-spin mr-2" />  </> : 'Save'}
                        </button>
                    </div>
                    {
                        rebrand.data !== false ?
                            <button
                                type="button"
                                onClick={() => setShow(true)}
                                className="demoLink mt-2"
                                style={{ display: "inline" }}
                            >
                                Remove Rebranding
                            </button>
                            : ''
                    }
                </form>
            </div>

            <SweetAlertTwo
                show={show}
                title="Are You Sure?"
                text=" Do you want to remove this rebranding!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#3085d6'
                cancelButtonColor='#d33'
                confirmButtonText='Yes, remove it!'
                confirmTitle='Removing...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={onConfirm}
                onCancel={() => setShow(false)}
            />
        </>
    )
}

export default Rebranding;