import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { onDeleteAcc, onEditClient } from "../../Actions/AccountManageAction";
import SweetAlertTwo from "../SweetAlertTwo";

const EditTeamClient = ({ type, user, index, fetchClientTeam }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [toggle, setToggle] = useState(false)
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })
    const [accountDetails, setAccountDetails] = useState({
        id: user.id,
        email: user.email,
        name: user.name,
        password: ''
    });

    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })

    const onDelete = (id) => {
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }
    const deleteClientTeam = (Swal) => {
        let data = { client_id: info.id }
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
        dispatch(onDeleteAcc(data, fetchClientTeam, setLoader, Swal))
    }

    const handleCancle = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    const onInputChange = (e) => {
        setPasswordMsg({ ...passwordMsg, msg: "" })
        setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value });
    }

    const handleEdit = () => {
        if (toggle) {
            setToggle(false)
        } else {
            setToggle(true)
            setAccountDetails({
                ...accountDetails,
                id: user.id,
                email: user.email,
                name: user.name,
                password: ''
            })
        }
    }

    const editClientTeam = () => {
        dispatch(onEditClient(accountDetails, fetchClientTeam, setLoader, setToggle))
    }

    const handleSubmit = (e, type) => {
        e.preventDefault();
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (type === "save") {
            if (accountDetails.password) {
                if (pattern.test(accountDetails.password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    editClientTeam();
                } else {
                    setPasswordMsg({ ...passwordMsg, msg:"Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
                }
            } else {
                setLoader(true);
                editClientTeam();
            }

        } else if (type === "cancel") {
            setToggle(false)
        }
    }

    return (
        <>
            <tr key={index}>
                <td><h6 className="text-wrapper m-0">{user?.name}</h6></td>
                <td><h6 className="text-wrapper m-0">{user?.email}</h6></td>
                <td>{user?.created}</td>
                <td className="expand-button">
                    <a className="action-icon delete-icon remove mb-1 mt-md-1"
                        title="Edit"
                    // data-toggle="collapse"
                    // data-parent="accordion89"
                    // href={`#client_${user?.id}`}
                    // aria-expanded="true"
                    >
                        <i style={{ cursor: "pointer" }} onClick={handleEdit} className="fas fa-edit"></i>
                    </a>
                    <span className="action-icon delete-icon remove mb-1 pl-xl-2  mt-md-1"
                        style={{ cursor: "pointer", color: "rgb(240, 83, 89)" }}
                        onClick={() => onDelete(user.id)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </span>
                </td>
            </tr>
            {toggle ? <tr className="hide-table-padding">
                <td colSpan="12" style={{ padding: "0px" }}>
                    <div id={`client_${user?.id}`} className="p-3 cl-table" style={{ width: "100%", background: "#F2F3F7" }}>
                        <div className="col-12">
                            <p className="text-white"></p>
                            <div className="mt-2 mb-0">
                                <h6 className=" text-left mb-4 mt-3"
                                    style={{ marginLeft: "-10px" }}>Edit {type}</h6>
                                <div>
                                    <form method="post" >
                                        <div className="row d-flex justify-content-center">
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-alt form-group-mb mt-0">
                                                    <label
                                                        className="input-label-pos m-0">Name</label>
                                                    <input
                                                        className="form-control input-dark-accnt personal-info w-100"
                                                        type="text" autoComplete="off" name="name" defaultValue={accountDetails.name}
                                                        required
                                                        onChange={(e) => onInputChange(e)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === ' ' || e.code === 32) {
                                                                const inputValue = e.target.value;
                                                                if (inputValue.length === 0 || inputValue[0] === ' ') {
                                                                    e.preventDefault();
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-alt form-group-mb mt-0">
                                                    <label className="input-label-pos m-0">Email
                                                        Address</label>
                                                    <input
                                                        className="form-control input-dark-accnt personal-info"
                                                        type="email" name="email"
                                                        placeholder="Email Address" readOnly
                                                        defaultValue={user.email}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-alt form-group-mb">
                                                    <label
                                                        className="input-label-pos m-0">Password <small>(leave
                                                            blank for same password)</small></label>
                                                    <input
                                                        className="form-control input-dark-accnt personal-info"
                                                        type="password" name="password"
                                                        placeholder="" autoComplete="off"
                                                        onChange={(e) => onInputChange(e)}
                                                    />
                                                     <p style={{ fontSize: 13, margin: "5px" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 p-1 text-left">
                                                <div className="form-group form-group-alt form-group-mb">
                                                    <label className="input-label-pos m-0">Account
                                                        Type</label>
                                                    <input
                                                        className="form-control input-dark-accnt personal-info"
                                                        type="text" name="Account Type"
                                                        placeholder="Account Type" readOnly
                                                        value={type} />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col col-md-12 cancel-update d-flex  justify-content-center text-center mt-5 mb-5">
                                            <div className="btn-cancl">
                                                <button type="button" className="cancl-btn   "
                                                    // data-toggle="collapse"
                                                    // data-parent="accordion89" href={`#client_${user.id}`}
                                                    // aria-expanded="true"

                                                    onClick={(e) => handleSubmit(e, 'cancel')}
                                                    style={{ display: "inline-block", marginRight: "10px" }}>Cancel</button>
                                            </div>
                                            <button
                                                type="submit"
                                                onClick={(e) => handleSubmit(e, 'save')}
                                                className="toggleupdate  btn-change7 "
                                                style={{ display: "inline-block", textTransform: "capitalize" }}>
                                                {loader ? <>Updating <i className="fa fa-spinner fa-spin mr-2" /> </> : 'Update'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr> : ""}

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteClientTeam}
                onCancel={handleCancle}
            />
        </>
    )
}

export default EditTeamClient;